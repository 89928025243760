<template>
  <CLoading :loading="loading" />
  <div class="row">
    <div class="col"></div>
    <div class="col-2 bg-white q-pa-sm">
      <div class="text-subtitle2 text-grey-8">期間選択</div>
      <div class="row no-wrap items-center">
        <DateRangeSelectorV2
          opens="left"
          :disable="false"
          v-model="dateRange"
          @update:onChangeDateRange="changeDateRange"
          class="full-width"
        ></DateRangeSelectorV2>
      </div>
      <div class="row q-col-gutter-xs q-mt-xs">
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="今日"
            class="full-width"
            @click="changeDate('today')"
            :class="currentDateType === 'today' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="昨日"
            class="full-width"
            @click="changeDate('yesterday')"
            :class="currentDateType === 'yesterday' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="今週"
            class="full-width"
            @click="changeDate('thisWeek')"
            :class="currentDateType === 'thisWeek' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="前週"
            class="full-width"
            @click="changeDate('lastWeek')"
            :class="currentDateType === 'lastWeek' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="今月"
            class="full-width"
            @click="changeDate('thisMonth')"
            :class="currentDateType === 'thisMonth' ? 'btn-save-grey' : ''"
          />
        </div>
        <div class="col">
          <q-btn
            outline
            dense
            size="xs"
            color="grey-6"
            label="前月"
            class="full-width"
            @click="changeDate('lastMonth')"
            :class="currentDateType === 'lastMonth' ? 'btn-save-grey' : ''"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="q-pa-md q-gutter-md text-center" :key="componentKey">
    <EChart
      v-if="friendsChart"
      :titleChart="chart_title"
      :xAxisData="friendsChart.xAxis"
      :seriesData="seriesData"
      :showLegend="true"
      :chartColor="'rgba(92, 252, 169, 1)'"
      :chartBgColor="'rgba(92, 252, 169, 0.4)'"
    ></EChart>
  </div>

  <div class="q-pa-md q-gutter-md">
    <q-table
      flat
      dense
      v-if="campaignsStatistic"
      :rows="campaignsStatistic"
      row-key="date"
      bordered
      :rows-per-page-options="[10]"
      separator="cell"
      :no-data-label="$t('messages.no_data_available')"
      class="text-grey-8"
    >
      <template v-slot:header>
        <q-tr>
          <q-th style="width: 150px"></q-th>
          <q-th>
            <span class="text-bold">{{ $t('label.total_display') }} </span>
          </q-th>
          <q-th>
            <span class="text-bold">{{ $t('label.click_rate') }}</span>
          </q-th>
          <q-th>
            <span class="text-bold">{{ $t('label.total_click') }}</span>
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="date" :props="props">{{ props.row.date }} </q-td>
          <q-td key="total_display" :props="props"> {{ numberWithCommas(props.row.total_display) }} </q-td>
          <q-td key="click_rate" :props="props"> {{ numberWithCommas(props.row.click_rate) }}% </q-td>
          <q-td key="total_click" :props="props"> {{ numberWithCommas(props.row.total_click) }}</q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { maska } from 'maska'
import { IDateRangePicker, IOverViewPopupRequest } from '@/utils/types'
import { ChartModel } from '@/models/chart-model'
import { ACTION_CAMPAIGN, ACTION_POPUP } from '@/store/actions'
import { Formater } from '@/utils/formater'
import { Watch } from 'vue-property-decorator'
import { DatetimeUtils } from '@/utils/datetime'
import EChart from '@/components/app/EChart.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'

@Options({
  components: { DateRangeSelectorV2, CLoading, EChart },
  directives: { maska },
})
export default class ChartOfPopupDetails extends mixins(BaseFormMixin) {
  loading = true
  firstLoad = true
  componentKey = 1
  chart_title = ''
  currentDateType = ''

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  friendsChart: ChartModel = new ChartModel([], [])

  campaignsStatistic: IOverViewPopupRequest[] = []

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get selectedCampaignId() {
    return this.$route.params.campaign_id || ''
  }

  get selectedPopupId() {
    return this.$route.params.popup_id || ''
  }

  async fetchSelectingApp() {
    this.campaignsStatistic = []
    if (!this.selectedCampaignId) {
      return
    }

    this.loading = true
    if (this.selectedPopupId) {
      const resp = await this.$store.dispatch(ACTION_POPUP.OVERVIEW_DETAIL, {
        app_id: this.selectedAppId,
        campaign_id: this.selectedCampaignId,
        popup_id: this.selectedPopupId,
        is_test_mode: false,
        date_from: DatetimeUtils.formatDate(this.dateRange.startDate),
        date_to: DatetimeUtils.formatDate(this.dateRange.endDate),
      })
      if (resp) {
        // eslint-disable-next-line
        this.campaignsStatistic = resp['popup']
      }
    } else {
      const resp = await this.$store.dispatch(ACTION_CAMPAIGN.OVERVIEW_DETAIL, {
        app_id: this.selectedAppId,
        campaign_id: this.selectedCampaignId,
        is_test_mode: false,
        date_from: DatetimeUtils.formatDate(this.dateRange.startDate),
        date_to: DatetimeUtils.formatDate(this.dateRange.endDate),
      })
      if (resp) {
        // eslint-disable-next-line
        this.campaignsStatistic = resp['campaign']
      }
    }
    this.initCharts()
    this.loading = false
  }

  initCharts(): void {
    this.friendsChart = new ChartModel([], [])
    const total = this.campaignsStatistic.length
    for (let index = total - 1; index >= 0; index--) {
      const item = this.campaignsStatistic[index]
      this.friendsChart.xAxis.push(item.date)
    }

    this.componentKey++
  }

  get seriesData() {
    const total_display: number[] = []
    const click_rate: number[] = []
    const total_click: number[] = []
    const total = this.campaignsStatistic.length
    for (let index = total - 1; index >= 0; index--) {
      const item = this.campaignsStatistic[index]
      total_display.push(item.total_display)
      click_rate.push(item.click_rate)
      total_click.push(item.total_click)
    }

    return [
      {
        name: this.$t('label.total_display'),
        type: 'line',
        data: total_display,
      },
      {
        name: this.$t('label.click_rate'),
        type: 'line',
        data: click_rate,
      },
      {
        name: this.$t('label.total_click'),
        type: 'line',
        data: total_click,
      },
    ]
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  changeDateRange(value) {
    if (this.firstLoad) {
      return
    }
    this.dateRange = value
    this.fetchSelectingApp()
  }

  changeDate(type) {
    if (type === this.currentDateType) {
      this.currentDateType = ''
    } else {
      this.currentDateType = type
    }
  }

  @Watch('currentDateType')
  setDateRange() {
    this.dateRange = DatetimeUtils.getDateRange(this.currentDateType)
    this.fetchSelectingApp()
  }

  @Watch('selectedCampaignId', { immediate: true })
  selectedCampaignIdChanged() {
    this.dateRange = DatetimeUtils.getDateRange('last7Days')
    this.fetchSelectingApp()
    this.firstLoad = false
  }

  mounted() {
    this.chart_title = this.$t('label.initial.chart_title')
  }
}
</script>

<style scoped lang="scss">
</style>
